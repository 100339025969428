import React from "react"
import Seo from "src/utils/seo"
import Layout from "components/Layout/Layout"
import SettingsManager from "components/SettingsManager/SettingsManager"
import Campaign from "components/Campaign/Campaign"

export default function Page() {
    return(
        <React.Fragment>
            <Seo title="Campaigns" />
            <Layout showActions={true}>
                <Campaign page="campaign" />
            </Layout>
            <SettingsManager/>
        </React.Fragment>
    )
}